import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import * as styles from "./ready.module.scss"

const Ready = ({ strapiData }) => {
  return (
    <div className={styles.ready}>
      <Container className={styles.pad}>
        <div className={styles.readyBg}>
          <Col lg={12} md={12} xs={12} sm={12}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white2_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
        </div>
      </Container>
    </div>
  )
}

export default Ready
