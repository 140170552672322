import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "../cyber-security/SimpleSteps.css"
import * as styles from "./EasySteps.module.scss"
import "./EasySteps.scss"
import "./progressSlider.scss"

const ContextAwareToggle = ({ children, eventKey, callback, ind }) => {
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = ind === eventKey

  return (
    <Accordion.Toggle
      className={isCurrentEventKey ? styles.headr : ""}
      as={Card.Header}
      onClick={decoratedOnClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const EasySteps = ({ strapiData }) => {
  const [imageInd, setImageInd] = useState(0)
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(progress => {
        const nextProgress = progress + 1
        const sliderProgress = document.querySelectorAll(`.isActivee`)
        sliderProgress[0].style.height = `${progress}%`

        if (current !== imageInd) {
          const elementWidth = window.getComputedStyle(sliderProgress[0]).height
          if (parseFloat(elementWidth) > 0)
            sliderProgress[0].style.height = `0%`

          setImageInd(current)
          setProgress(0)
        }

        if (nextProgress > 100) {
          sliderProgress[0].style.height = `0%`
          const nextSlide = () => {
            const nextIndex = imageInd + 1
            if (nextIndex >= 5) {
              return 0
            }
            return nextIndex
          }
          setProgress(0)
          setImageInd(nextSlide())
          setCurrent(nextSlide())
          return 0
        }
        return nextProgress
      })
    }, 40)

    return () => clearInterval(interval)
  }, [progress, current, imageInd])

  return (
    <div className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <h2
              className={`${styles.head}  mb-0`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description2}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className="faqsAccordian djangoEasySteps">
              <Accordion defaultActiveKey={`ek-${imageInd}`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card
                    key={i}
                    border="light"
                    style={{ overflow: "visible" }}
                    onClick={() => setCurrent(i)}
                  >
                    <ContextAwareToggle
                      eventKey={`ek-${i}`}
                      ind={`ek-${imageInd}`}
                    >
                      <div className={styles.faqsNum}>
                        {imageInd === i && (
                          <div>
                            <span className={styles.serial2}>
                              Step 0{+(i + 1)}
                            </span>
                            <span className={styles.serial}>
                              Step 0{+(i + 1)}
                            </span>
                          </div>
                        )}
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse
                      className={`${imageInd === i && "show"}`}
                    >
                      <Card.Body className={styles.textColor}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        />
                        <div
                          className={`progress-barr`}
                          id={`sliderProgress-${i}`}
                        >
                          <span
                            className={`${imageInd === i && "isActivee"}`}
                          ></span>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className={styles.crossImg}>
              <img
                src={
                  strapiData?.cards[imageInd]?.image1 &&
                  strapiData?.cards[imageInd]?.image1[0]?.localFile?.publicURL
                }
                decoding="async"
                loading="lazy"
                alt={"Easy Steps"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EasySteps
