// extracted by mini-css-extract-plugin
export var Android = "Discover-module--Android--63643";
export var arrowDiv = "Discover-module--arrowDiv--96306";
export var arrowImg = "Discover-module--arrowImg--f846e";
export var boxContent = "Discover-module--boxContent--b2abe";
export var cards = "Discover-module--cards--46488";
export var cardsContent = "Discover-module--cardsContent--57d5e";
export var cardsZ = "Discover-module--cardsZ--d3bc7";
export var description = "Discover-module--description--e0c5b";
export var djangoArrowImg = "Discover-module--djangoArrowImg--a08fc";
export var estabBtn = "Discover-module--estabBtn--dbb76";
export var explorrBtn = "Discover-module--explorrBtn--46ddc";
export var finImg = "Discover-module--finImg--0c4fe";
export var heading = "Discover-module--heading--582b6";
export var tabImg = "Discover-module--tabImg--f676a";
export var tabbingCards = "Discover-module--tabbingCards--99d95";
export var tabs = "Discover-module--tabs--982f1";