import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Dynamic.module.scss"

const Dynamic = ({ strapiData }) => {
  return (
    <div className={`${styles.dynamic}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData[0]?.subTitle,
          }}
        />
        <div className={styles.cardBg}></div>
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {strapiData[0]?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i} className={styles.jango}>
              <div className={styles.card}>
                <div className={styles.imgs}>
                  <img
                    className={styles.jangoImg}
                    src={e?.image2[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.image2[0]?.alternativeText}
                  />
                  <img
                    className={styles.jangoImg}
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                </div>
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={styles.benefits}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
          />
          <div
            className={styles.description2}
            dangerouslySetInnerHTML={{
              __html: strapiData[1]?.subTitle,
            }}
          />
          <Row className="gap-30">
            {strapiData[1]?.cards?.map((e, i) => (
              <Col lg={3} md={6} xs={12} sm={12} className="gap-30" key={i}>
                <div className={styles.cards2}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "0px" }}
                  ></lottie-player>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <div className={`${styles.bannerBtn}`}>
            {strapiData[1]?.buttons[0] && (
              <Link
                to={strapiData[1]?.buttons[0]?.url}
                className={styles.btn_white2_border}
              >
                {strapiData[1]?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Dynamic
