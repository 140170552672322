// extracted by mini-css-extract-plugin
export var accImg = "technical-module--accImg--644d8";
export var approachImg = "technical-module--approachImg--b2d3b";
export var btn_white1_border = "technical-module--btn_white1_border--d611b";
export var description = "technical-module--description--3244f";
export var finttechRight = "technical-module--finttechRight--bd24d";
export var head = "technical-module--head--edb49";
export var heading = "technical-module--heading--da105";
export var keyBtn = "technical-module--keyBtn--a131a";
export var nextGenBehold = "technical-module--nextGenBehold--fedc7";
export var quesColor = "technical-module--quesColor--97fac";
export var quesColorSelected = "technical-module--quesColorSelected--650b1";
export var someColor = "technical-module--someColor--0842b";
export var textColor = "technical-module--textColor--d41b5";