// extracted by mini-css-extract-plugin
export var crossImg = "EasySteps-module--crossImg--e6433";
export var description = "EasySteps-module--description--aad11";
export var description2 = "EasySteps-module--description2--92337";
export var faqButton = "EasySteps-module--faqButton--fec6f";
export var faqsNum = "EasySteps-module--faqsNum--ec809";
export var faqsSub = "EasySteps-module--faqsSub--e45a5";
export var head = "EasySteps-module--head--ace53";
export var headr = "EasySteps-module--headr--18516";
export var quesColor = "EasySteps-module--quesColor--5c8d1";
export var quesColorSelected = "EasySteps-module--quesColorSelected--4ca73";
export var reverse = "EasySteps-module--reverse--2bc3e";
export var section = "EasySteps-module--section--8ed19";
export var serial = "EasySteps-module--serial--8545f";
export var serial2 = "EasySteps-module--serial2--2f21d";
export var textColor = "EasySteps-module--textColor--270b7";