import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import arrow from "../../images/mobile-app-developmemt/arrow.svg"
import "./technical.scss"
import * as styles from "./Discover2.module.scss"

const Discover2 = ({ strapiData }) => {
  const addOrRemoveClass = event => {
    const clickedElement = event.currentTarget

    var allBoxes = document.getElementsByClassName("cardAcc")
    // console.log("Runnnn", clickedElement)
    for (var i = 0; i < allBoxes.length; i++) {
      allBoxes[i].classList.remove("cardClick")
    }
    clickedElement.classList.toggle("cardClick")
  }

  return (
    <div className={styles.Discover2}>
      <Container>
        <Row>
          <Col xl={6} md={12}>
            <h2
              className={styles.bannerHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className={`${styles.cards}`}>
              {strapiData?.cards?.map((e, i) => (
                <div
                  className={`${styles.industries} cardAcc ${
                    i === 0 && "cardClick"
                  }`}
                  onClick={addOrRemoveClass}
                >
                  <div className="d-flex justify-content-between">
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0px",
                      }}
                    ></lottie-player>
                    <div className={` text-end ${styles.arrowDiv}`}>
                      {e?.buttons[0]?.url && (
                        <Link to={e?.buttons[0]?.url}>
                          <img
                            className={styles.arrowImg}
                            src={arrow}
                            decoding="async"
                            loading="lazy"
                            alt="arrow"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  ></p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Discover2
