import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/DJango/tick.svg"
import FaqForm from "../common/hireDeveloperForm"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="djangoBanner" className={styles.djangoBanner}>
      <Container>
        <Row>
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <span className={styles.bannerHeading}>
                <h1>Hire Django Developers</h1>
                Ready To Start In 48 Hours
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={`${styles.story} d-flex`}>
              {strapiData?.cards?.map((el, i) => (
                <div className={styles.success} key={i}>
                  <img src={tick} alt="icon" />
                  <p>{el?.title}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col xl={6} md={12} lg={6} className={styles.form}>
            <FaqForm pageName={pageName} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
