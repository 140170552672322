// extracted by mini-css-extract-plugin
export var description = "TechStacks-module--description--e1e66";
export var frame = "TechStacks-module--frame--7f670";
export var heading = "TechStacks-module--heading--df28a";
export var name = "TechStacks-module--name--38018";
export var name1 = "TechStacks-module--name1--810cb";
export var name3 = "TechStacks-module--name3--4c22c";
export var name5 = "TechStacks-module--name5--3a06f";
export var name7 = "TechStacks-module--name7--7f863";
export var name9 = "TechStacks-module--name9--df4b5";
export var stacks = "TechStacks-module--stacks--6197d";
export var tech = "TechStacks-module--tech--b51b0";
export var techStacks = "TechStacks-module--techStacks--9c52e";