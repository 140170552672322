import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import why from "../../images/DJango/why.png"
import * as styles from "./WhyHire.module.scss"

const WhyHire = ({ strapiData }) => {
  return (
    <div className={styles.hireCyber}>
      <Container>
        <Row className="gap-30">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hire}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/why_909a50688b9a29ff57b8d66f4778f846_223b27ac85.png"
              decoding="async"
              loading="lazy"
              alt="Why Hire Python Django Developers From Us?"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyHire
