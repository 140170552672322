// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--aa281";
export var circleTes = "Testimonials2-module--circleTes--f3ba0";
export var content = "Testimonials2-module--content--bca85";
export var des = "Testimonials2-module--des--32ea0";
export var iconContainer = "Testimonials2-module--iconContainer--6c354";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--bcd91";
export var personBg = "Testimonials2-module--personBg--28291";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--35e1b";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--7f041";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--e3881";
export var row = "Testimonials2-module--row--68fea";
export var section = "Testimonials2-module--section--dd0a3";
export var tesBtn = "Testimonials2-module--tesBtn--55904";
export var tesDots = "Testimonials2-module--tesDots--21399";
export var tesRec = "Testimonials2-module--tesRec--e2e26";
export var testimonials = "Testimonials2-module--testimonials--e72d8";