// extracted by mini-css-extract-plugin
export var bannerBtn = "Dynamic-module--bannerBtn--e8b44";
export var benefits = "Dynamic-module--benefits--98826";
export var btn_white2_border = "Dynamic-module--btn_white2_border--7d94a";
export var card = "Dynamic-module--card--b0038";
export var cardBg = "Dynamic-module--cardBg--1a9de";
export var cardWrapper = "Dynamic-module--cardWrapper--6d733";
export var cards2 = "Dynamic-module--cards2--f1285";
export var description = "Dynamic-module--description--3e65d";
export var description2 = "Dynamic-module--description2--e6f23";
export var dynamic = "Dynamic-module--dynamic--99d08";
export var heading = "Dynamic-module--heading--a4997";
export var imgs = "Dynamic-module--imgs--1636b";
export var jango = "Dynamic-module--jango--9d1ac";
export var jangoImg = "Dynamic-module--jangoImg--95dcc";