// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--1f839";
export var clientDots2 = "Testimonials-module--clientDots2--44f5a";
export var content = "Testimonials-module--content--d18cf";
export var dots3 = "Testimonials-module--dots3--5a0d7";
export var hireArrow = "Testimonials-module--hireArrow--35027";
export var iconContainer = "Testimonials-module--iconContainer--6e6f7";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--0e61b";
export var imgBtn = "Testimonials-module--imgBtn--09eeb";
export var person = "Testimonials-module--person--3272b";
export var personBg = "Testimonials-module--personBg--136d6";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--3ae81";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--e12ce";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--179bc";
export var row = "Testimonials-module--row--ea0c2";
export var section = "Testimonials-module--section--7b25c";
export var staffRec = "Testimonials-module--staffRec--d6460";
export var testName = "Testimonials-module--testName--2eab4";
export var testimonials = "Testimonials-module--testimonials--729b9";
export var testimonialsContent = "Testimonials-module--testimonialsContent--296f3";
export var vector2 = "Testimonials-module--vector2--59533";